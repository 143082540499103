import { Box, Button, Stack, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/use-auth';

import Summary from './components/Summary';
import ProfileTabs from './components/ProfileTabs';
import { LoadingSpinner } from 'src/components/loading-spinner';
import Container from '../../components/Container';
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionContainer from '../../components/SectionContainer';

export default function Profile() {
  const { user } = useAuth();
  const account = user?.active_account;

  useEffect(() => {
    document.title = 'CountOn Profile';
  }, []);

  if (!account || !account.display_name) return <LoadingSpinner />;

  return (
    <Box>
      <Summary />
      <ProfileTabs />
      <LogoutPane />
      <AdminProfilePanel />
    </Box>
  );
}

function AdminProfilePanel() {
  const { user } = useAuth();

  if (user?.role !== 'admin') return null;
  return (
    <Container>
      <Stack direction="column" spacing={1} sx={{ my: 3 }}>
        <Typography variant="h5">Admin Only</Typography>
        <Typography>Current user_id: {user.user_id}</Typography>
        <Typography>
          Current active_account_id: {user.active_account_id}
        </Typography>
      </Stack>
    </Container>
  );
}

const LogoutPane = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    await logout();
    navigate('/login');
  }

  return (
    <SectionContainer
      backgroundColor="pastel.light"
      sx={{ display: 'flex', justifyContent: 'flex-end', py: 3 }}
    >
      <Button
        variant="contained"
        onClick={handleLogout}
        endIcon={<LogoutIcon />}
      >
        Logout
      </Button>
    </SectionContainer>
  );
};
