import { CSSProperties, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Edit, Link } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import TrustableAvatar from 'src/components/AccountAvatar';
import Container from 'src/components/Container';
import SocialStack from 'src/pages/public-profile/components/SocialStack';
import ShowMoreMarkdownBox from 'src/pages/shared/ShowMoreMarkdownBox';
import EditProfileModal from './EditProfileModal';
import { useAuth } from '../../../hooks/use-auth';

const baselineReactMarkdownStyle = {
  mt: 1,
  '& div.markdown': { fontSize: '18px' },
  '& div.markdown p': { marginBottom: 0 },
};

const Summary = () => {
  const { user } = useAuth();
  const account = user?.active_account;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const [shouldOpenEditProfileModal, setShouldOpenEditProfileModal] =
    useState(false);

  const isRecommender = account.account_type !== 'personal';

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/r/${account.account_name}`,
    );
    toast.success('Your personal link has been copied to clipboard!');
  };

  const onEditProfileCLick = () => {
    setShouldOpenEditProfileModal(true);
  };

  return (
    <Container {...{ pb: { xs: 3, sm: 4 } }}>
      <Stack
        direction="row"
        spacing={{ xs: 3, sm: 6 }}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <TrustableAvatar avatarSize={{ xs: 80, sm: 200 }} account={account} />
        </Box>
        <Stack direction="column" width={isRecommender ? 800 : 1000}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ flexFlow: 'row wrap' }}
          >
            <Box marginBottom={{ xs: 1, sm: 0 }}>
              <Typography
                sx={{ fontSize: { xs: 20, sm: 32 }, fontWeight: 600 }}
              >
                {account.display_name}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 16, sm: 20 }, fontWeight: 400 }}
                mb="5px"
              >
                @{account.account_name}
              </Typography>
            </Box>

            <Box>
              {isSm && (
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    mr: 2,
                    fontSize: 15,
                    fontWeight: 600,
                    borderRadius: { xs: 6, sm: '5px' },
                  }}
                  onClick={onEditProfileCLick}
                >
                  <Edit color="secondary" sx={{ mr: { xs: 0, sm: 0.5 } }} />
                  Edit profile
                </Button>
              )}

              <Button
                variant="contained"
                sx={{
                  fontSize: 15,
                  fontWeight: 600,
                  borderRadius: { xs: 6, sm: '5px' },
                }}
                onClick={copyUrlToClipboard}
              >
                <Link sx={{ mr: 0.5, rotate: '-45deg' }} />
                {isSm && 'Share'}
              </Button>
            </Box>
          </Box>
          {isSm && (
            <Box>
              <ShowMoreMarkdownBox
                maxHeight="75px"
                markdownStyle={baselineReactMarkdownStyle as CSSProperties}
              >
                {account.short_desc || 'No summary yet'}
              </ShowMoreMarkdownBox>
              <SocialStack account={account} />
            </Box>
          )}
        </Stack>
      </Stack>
      {!isSm && (
        <Stack direction="column">
          <ShowMoreMarkdownBox
            maxHeight="75px"
            markdownStyle={baselineReactMarkdownStyle as CSSProperties}
          >
            {account.short_desc || 'No summary yet'}
          </ShowMoreMarkdownBox>
          <SocialStack account={account} />
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              fontSize: 15,
              fontWeight: 600,
              border: '1.5px solid',
              mt: 2,
            }}
            onClick={onEditProfileCLick}
          >
            <Edit color="secondary" sx={{ mr: { xs: 0, sm: 0.5 } }} />
            Edit profile
          </Button>
        </Stack>
      )}
      {shouldOpenEditProfileModal && (
        <EditProfileModal
          isOpen={shouldOpenEditProfileModal}
          setIsOpen={setShouldOpenEditProfileModal}
        />
      )}
    </Container>
  );
};

export default Summary;
