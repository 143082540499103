import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import log from 'loglevel';
import SectionContainer from '../../components/SectionContainer';
import { useState } from 'react';
import ConfirmButton from './ConfirmButton';
import { EditFactDialog } from '../your-profile/components/EditFactDialog';
import {
  useDeleteFactMutation,
  useGetFactsCountForAccount,
  useGetFactsForAccount,
} from '../../hooks/fact-hooks';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import AdminOnlyWarning from '../your-profile/components/AdminOnlyNotice';
import { LoadingSpinner } from '../../components/loading-spinner';

export default function Facts() {
  const { user } = useAuth();
  let { account_name } = useParams();
  if (!account_name) {
    account_name = user.active_account.account_name;
  }
  log.debug('account_name for facts:', account_name);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const pageCount = 50;
  const [factId, setFactId] = useState<string>(null);
  const factsQuery = useGetFactsForAccount(account_name, page, pageCount);
  const { data: countData } = useGetFactsCountForAccount(account_name);

  const handleCreateFact = () => {
    setFactId(null);
    setOpen(true);
  };

  const handlePageChange = (_, value: number) => {
    setPage(value);
  };

  const handleEditClick = (factId: string) => {
    setFactId(factId);
    setOpen(true);
  };

  if (!user) return <LoadingSpinner />;
  if (user.role !== 'admin') {
    return <AdminOnlyWarning />;
  }
  if (factsQuery.isLoading) return <LoadingScreen />;

  return (
    <SectionContainer sx={{ mt: 2 }}>
      <Stack
        direction="column"
        spacing={2}
        alignItems={'flex-start'}
        sx={{ mb: 2 }}
      >
        <Button variant="contained" onClick={handleCreateFact}>
          Add Fact
        </Button>
      </Stack>
      {countData?.n && factsQuery.data ? (
        <Stack direction="column" sx={{ mt: 2 }}>
          {countData?.n && (
            <Pagination
              count={Math.ceil(countData.n / pageCount)}
              page={page}
              onChange={handlePageChange}
            />
          )}
          <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 70, textAlign: 'center' }}>
                    #
                  </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Text</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {factsQuery.data.map((fact) => (
                  <TableRow key={fact.fact_id} sx={{ height: 70 }}>
                    <TableCell sx={{ textAlign: 'center', width: 120 }}>
                      <ShowIndex
                        index={fact.row}
                        base_id={fact.fact_id}
                        onEditClick={() => handleEditClick(fact.fact_id)}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 180 }}>{fact?.fact_type}</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>
                      {fact.fact_text}
                    </TableCell>
                    <TableCell sx={{ width: 200 }}>
                      {displayDate(fact.created_at)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : (
        <Typography>No facts for this account</Typography>
      )}
      {open && (
        <EditFactDialog
          fact_id={factId}
          open={open}
          setOpen={setOpen}
          account_name={account_name}
        />
      )}
    </SectionContainer>
  );
}

const ShowIndex = ({ index, base_id, onEditClick }) => {
  const [hover, setHover] = useState(false);
  const deleteMutation = useDeleteFactMutation(base_id);
  const handleDelete = () => {
    log.debug('delete', index, base_id);
    deleteMutation.mutate({});
  };

  if (deleteMutation.isLoading)
    return (
      <Box>
        <CircularProgress size={15} />
      </Box>
    );

  return (
    <Box onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
      {hover ? (
        <Stack direction="row" spacing={0} justifyContent={'center'}>
          <ConfirmButton isIcon={true} onConfirm={handleDelete} />
          <IconButton size="small" onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        </Stack>
      ) : (
        <div>{index}</div>
      )}
    </Box>
  );
};

const LoadingScreen = () => {
  return <SectionContainer sx={{ mt: 2 }}>Loading...</SectionContainer>;
};

const displayDate = (dateStr: string) => {
  // Convert to a format recognized by the Date constructor (ISO 8601 format)
  const isoStr = dateStr.replace(' ', 'T') + 'Z';
  const dateObj = new Date(isoStr);
  const localTimestamp = dateObj.toLocaleString();
  return localTimestamp;
};
