import log from 'loglevel';
import { deviceDetect } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

export interface SegmentConfig {
  writeKey: string;
}

const ENABLED =
  process.env['REACT_APP_ENABLE_SEGMENT'] === 'true' ||
  ['prod', 'gamma'].includes(process.env['REACT_APP_STAGE']);

// 75 was picked on Sep 10 by Steve because we had 52 creators on that day.
// If somebody chose all values and creator types during onboarding, they could
// try to add them all at once. This is really here to detect infinite loops,
// which should result in many more events per second.
const EVENTS_PER_SECOND = 75;

// For tracking the number of events within a unix seconds timestamp
const RateLimitStorage = {
  timestamp: 0,
  count: 0,
};

declare global {
  interface Window {
    analytics: any;
  }
}

function generateScriptFunction(writeKey: string): string {
  return `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${writeKey}";;analytics.SNIPPET_VERSION="4.15.3";
    analytics.load("${writeKey}");
    }}();
  `;
}

function getType(deviceInfo) {
  if (deviceInfo.isMobile) {
    return 'mobile';
  }

  if (deviceInfo.isTablet) {
    return 'tablet';
  }

  if (deviceInfo.isDesktop) {
    return 'desktop';
  }

  if (deviceInfo.isBrowser) {
    return 'browser';
  }

  return 'unknown';
}

function getOs(deviceInfo) {
  return {
    name: deviceInfo.os || deviceInfo.osName,
    version: deviceInfo.osVersion,
  };
}

function getModel(deviceInfo) {
  return deviceInfo.model || deviceInfo.browserName;
}

function getDeviceInfo() {
  const info = deviceDetect(window.navigator.userAgent);

  //console.log('DEVICE INFO');
  //console.dir(info);

  return {
    os: getOs(info),
    device: {
      type: getType(info),
      model: getModel(info),
    },
  };
}

// This can throw an error when many legitimate events are being sent, like when
// an account has many experts all added at once.
function checkRateLimit() {
  const now = Math.floor(Date.now() / 1000);

  if (RateLimitStorage.timestamp !== now) {
    RateLimitStorage.timestamp = now;
    RateLimitStorage.count = 0;
  } else {
    RateLimitStorage.count += 1;
  }

  if (RateLimitStorage.count > EVENTS_PER_SECOND) {
    throw new Error('Segment Rate Limit Exceeded');
  }
}

class Segment {
  sessionId: string;

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.identify = this.identify.bind(this);
    this.page = this.page.bind(this);
    this.pageManual = this.pageManual.bind(this);
    this.track = this.track.bind(this);
    this.getAnonymousId = this.getAnonymousId.bind(this);
    this.getSegmentId = this.getSegmentId.bind(this);

    let guestId = localStorage.getItem('guestId');
    if (!guestId) {
      guestId = 'counton-' + uuidv4();
      localStorage.setItem('guestId', guestId);
    }
    this.sessionId = guestId;
  }
  initialized = false;
  // Be wary with this - page views can be called before identify (probably a
  // bug...)
  userId = null;

  initialize(config: SegmentConfig) {
    if (!ENABLED) {
      log.info('Segment not enabled - skipping initialization');
      return;
    }

    if (this.initialized) {
      log.debug(
        'SEGMENT - Already Initialized - Skipping subsequent initialization call',
      );
      return;
    }

    const script = document.createElement('script');

    script.innerHTML = generateScriptFunction(config.writeKey);

    document.head.insertBefore(script, document.head.lastChild.nextSibling);
    this.initialized = true;
  }

  clearState() {
    if (!ENABLED) {
      return;
    }

    log.debug('SEGMENT - Clearing State');
    this.userId = null;
    // Use when logging out, clearing cache, etc

    // This clears Segment cookies and local storage. This is important for debugging
    // so we can test if anonymous users are being tracked correctly and subsequently
    // linked to their logged in events.

    if (process.env['REACT_APP_STAGE'] !== 'prod') {
      console.log("SEGMENT - Reset state because we're in DEV");
      window.analytics.reset();
    }
  }

  identify(userId: string, payload?: any) {
    if (!ENABLED) {
      return;
    }

    if (!this.initialized) {
      log.error('SEGMENT - Identify Failed - not initialized');
      return;
    }

    if (!window.analytics) {
      log.error('SEGMENT - Identify Failed - window.analytics not found');
      return;
    }

    checkRateLimit();

    // It's ok to call identify without a userID. See
    // https://segment.com/docs/connections/spec/best-practices-identify/
    if (userId) {
      this.userId = userId;
      window.analytics.identify(userId, payload);
    } else {
      log.info('SEGMENT - logging Identify without userId, just FYI');
      window.analytics.identify(payload);
    }
  }

  page(category: string, pageDescription: string, payload?: any) {
    if (!ENABLED) {
      return;
    }

    if (!this.initialized || !window.analytics) {
      log.warn('SEGMENT - Page Failed - not initialized');
      return;
    }

    // review-search needs to be handled manually because it auto-loads offscreen
    // but doesn't actually become visible until the user clicks the side button.
    if (pageDescription === '/review-search') return;
    if (pageDescription === '/ext/review-search') return;
    if (pageDescription === '/ext/v1/expertise') return;

    const pageMap = {
      '/p/': 'Profile',
      '/r/': 'Profile',
      '/ext/search': 'Extension Search',
      '/ext/recommend': 'Extension Popup',
      '/search': 'Global Search',
      '/admin': 'Admin Page',
      '/shop/': 'Detail Page',
      '/blog/': 'Blog Page',
      '/analyze-page/': 'Analyze Page',
    };

    let pageName = pageDescription;
    for (let key of Object.keys(pageMap)) {
      const val = pageMap[key];
      if (pageDescription.startsWith(key)) pageName = val;
    }

    // This is annoying, but when you first load the dashboard, there's a 'page'
    // segment event before the identify event, so we can't use the presence of
    // the userId to determine if we're on the dashboard or not. I'm just going
    // to leave it as the ambiguous "HomeDash" for now.
    if (pageDescription === '/') pageName = 'HomeDash';

    checkRateLimit();

    window.analytics.page(category, pageName, payload, {
      context: getDeviceInfo(),
    });
  }

  /**
   * the page call is fired automatically, but sometimes we don't want that
   * (like hidden pages that shouldn't be fired until actually displayed). In
   * those cases, add an exception to the .page call and then use this when the
   * page is actually viewed.
   *
   * @param category
   * @param pageDescription
   * @param payload
   * @returns
   */
  pageManual(category: string, pageDescription: string, payload?: any) {
    if (!ENABLED) {
      return;
    }

    if (!this.initialized || !window.analytics) {
      log.warn('SEGMENT - Page Failed - not initialized');
      return;
    }

    checkRateLimit();

    window.analytics.page(category, pageDescription, payload, {
      context: getDeviceInfo(),
    });
  }

  track(action: string, payload?: any) {
    if (!ENABLED) {
      return;
    }

    if (!this.initialized || !window.analytics) {
      log.warn('SEGMENT - Track Failed - not initialized');
      return;
    }

    checkRateLimit();

    // Always add the path to a tracked event
    window.analytics.track(
      action,
      {
        path: window.location.pathname,
        ...payload,
      },
      getDeviceInfo(),
    );
  }

  /**
   * Don't use this... sigh.... see useGuestId() hook instead.
   *
   * Return the segment ID if it exists. Some browser extensions prevent that
   * though. So if it doesn't exist, return a session ID (could change a LOT for
   * the same user) but should be stable at least until the next page load.
   * @returns
   */
  getAnonymousId(): string {
    let aid = this.getSegmentId();
    if (!aid) {
      aid = this.sessionId;
    }
    return aid;
  }

  getSegmentId() {
    try {
      const segmentId = window.analytics.user().anonymousId();
      return segmentId;
    } catch (e) {
      log.debug('SEGMENT - Error getting Segment ID');
      return null;
    }
  }
}

export const segment = new Segment();
