import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SectionContainer from '../../components/SectionContainer';
import { EditProductDialog } from '../admin/shared/AdminWidgets';
import { useState } from 'react';
import { useAuth } from '../../hooks/use-auth';
import { useGetProductsForAccount } from '../../hooks/product-hooks';
import { RouterLink } from '../../components/router-link';
import EditIcon from '@mui/icons-material/Edit';

export default function YourProducts() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState<string>(null);
  const productsQuery = useGetProductsForAccount(user.active_account_id);

  const handleIdClick = (productId) => {
    setProductId(productId);
    setOpen(true);
  };

  const handleCreateProduct = () => {
    setProductId(null);
    setOpen(true);
  };

  return (
    <SectionContainer sx={{ mt: 2 }}>
      <Button variant="contained" onClick={handleCreateProduct}>
        Add Product
      </Button>
      {open && (
        <EditProductDialog
          product_id={productId}
          open={open}
          setOpen={setOpen}
        />
      )}
      {productsQuery.data && (
        <TableContainer component={Paper} sx={{ my: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsQuery.data.map((product: any, i) => (
                <TableRow key={product.product_id}>
                  <TableCell sx={{ width: 10 }}>{i}</TableCell>
                  <TableCell sx={{ width: 350, fontSize: 12 }}>
                    <RouterLink to={`/shop/${product.product_id}`}>
                      {product.product_id}
                    </RouterLink>
                    <IconButton
                      onClick={() => handleIdClick(product.product_id)}
                    >
                      <EditIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>{product.brand}</TableCell>
                  <TableCell sx={{ width: 300, height: 40 }}>
                    <Box sx={{ width: 300, height: 40, overflow: 'hidden' }}>
                      {product.title}
                    </Box>
                  </TableCell>
                  <TableCell>{product.created_at}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </SectionContainer>
  );
}
