import { Button, Stack, TextField } from '@mui/material';
import SectionContainer from '../../components/SectionContainer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RouterLink } from '../../components/router-link';
import { useGetFact } from '../../hooks/fact-hooks';
import { EditFactDialog } from './components/EditFactDialog';
import { useAuth } from '../../hooks/use-auth';

export default function FactEditor() {
  const { user } = useAuth();

  let { account_name } = useParams();
  if (!account_name) {
    account_name = user?.active_account?.account_name;
  }
  const { fact_id } = useParams();
  const [open, setOpen] = useState(false);
  const [factText, setFactText] = useState('');
  const [factType, setFactType] = useState('');

  const factQuery = useGetFact(fact_id);

  const handleEdit = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!factQuery.data) return;
    setFactText(factQuery.data.fact?.fact_text || '');
    setFactType(factQuery.data.fact?.fact_type || '');
  }, [factQuery.data]);

  if (factQuery.isLoading || !user) return <LoadingScreen />;
  if (factQuery.isError) return <ErrorScreen />;

  // If loading is done, but factQuery.data is null, then the fact_id is invalid
  if (!factQuery.data?.fact) return <InvalidFactId />;

  return (
    <SectionContainer sx={{ my: 3 }}>
      <Stack direction="column" spacing={2}>
        <RouterLink to={`/profile/facts`}>Back to all facts</RouterLink>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            disabled
            id="fact_type"
            name="fact_type"
            label="Fact Type"
            value={factType}
            onChange={(e) => setFactType(e.target.value)}
          />
          <Button onClick={handleEdit} variant="contained">
            Edit
          </Button>
        </Stack>
        <TextField
          fullWidth
          multiline
          disabled
          rows={2}
          id="fact_text"
          name="fact_text"
          label="Fact Text"
          value={factText}
          onChange={(e) => setFactText(e.target.value)}
        />
      </Stack>
      {open && (
        <EditFactDialog
          fact_id={fact_id}
          account_name={account_name}
          open={open}
          setOpen={setOpen}
        />
      )}
    </SectionContainer>
  );
}

const InvalidFactId = () => {
  return (
    <SectionContainer sx={{ my: 3 }}>
      <Stack direction="column" spacing={2}>
        <RouterLink to={`/profile/facts`}>Back to all facts</RouterLink>
        <div>That Fact ID does not exist</div>
      </Stack>
    </SectionContainer>
  );
};

const LoadingScreen = () => {
  return (
    <SectionContainer sx={{ my: 3 }}>
      <div>Loading...</div>
    </SectionContainer>
  );
};

const ErrorScreen = () => {
  return (
    <SectionContainer sx={{ my: 3 }}>
      <div>Error!</div>
    </SectionContainer>
  );
};
