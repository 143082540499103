import { Avatar, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface AccountAvatarProps {
  account: any;
  size?: number;
  onClick?: () => void;
}

/**
 * Display an Avatar for the given account. When clicked, it goes to
 * their public profile.
 *
 * TODO: Consider adding an option account_name, if supplied it pulls
 * data from the server rather than requiring the account object. Will
 * make this widget useful in more places
 *
 * @param {*} param0
 * @returns
 */
export default function AccountAvatar({
  account,
  size = 60,
  onClick,
}: AccountAvatarProps) {
  const navigate = useNavigate();

  const defaultOnAvatarClick = () => {
    navigate(`/p/${account.account_name}`);
  };

  const handleAvatarClick = () => {
    onClick ? onClick() : defaultOnAvatarClick();
  };

  if (!account)
    return (
      <Skeleton
        variant="circular"
        width={size}
        height={size}
        animation="wave"
      />
    );

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: size / 2,
        border: 0,
        cursor: onClick ? 'pointer' : undefined,
      }}
      alt={account.display_name}
      src={account.avatar_url}
      onClick={handleAvatarClick}
    >
      {account.avatar_url ? null : account.display_name.slice(0, 2)}
    </Avatar>
  );
}
