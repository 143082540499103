import { Box, Tab, Tabs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import SectionContainer from 'src/components/SectionContainer';
import { TabItem } from '../types';
import TrustedExperts from './TrustedExperts';
import { YourAISettings } from '../YourAISettings';
import { useAuth } from '../../../hooks/use-auth';
import Facts from '../../shared/Facts';
import YourContent from '../YourContent';
import YourReviews from '../YourReviews';
import YourProducts from '../YourProducts';

const tabItems = [
  { label: TabItem.EXPERTS, path: '', show: true },
  { label: TabItem.CONTENT, path: 'content', show: true },
  { label: TabItem.AI_SETTINGS, path: 'ai_settings', show: false },
  { label: TabItem.FACTS, path: 'facts', show: false },
  { label: TabItem.PRODUCTS, path: 'products', show: false },
  { label: TabItem.REVIEWS, path: 'reviews', show: false },
  //{ label: TabItem.SAVES, path: 'saves', show: false },
];

const ProfileTabs = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const selectedTab = getSelectedTab(pathname);

  const renderTab = () => {
    switch (selectedTab) {
      case TabItem.EXPERTS:
        return <TrustedExperts />;
      case TabItem.CONTENT:
        return <YourContent />;
      case TabItem.AI_SETTINGS:
        return <YourAISettings />;
      case TabItem.REVIEWS:
        return <YourReviews />;
      case TabItem.FACTS:
        return <Facts />;
      case TabItem.PRODUCTS:
        return <YourProducts />;
      //case TabItem.SAVES:
      //  return <RecentlySavedItems />;
      default:
        return <TrustedExperts />;
    }
  };

  return (
    <Box>
      <SectionContainer
        sx={{
          display: 'flex',
          justifyContent: 'center',
          borderBottom: '2px solid #E0E0E0',
        }}
      >
        <Tabs
          value={selectedTab}
          aria-label="Global search for products and experts"
        >
          {tabItems
            .filter((item) => user?.role === 'admin' || item.show)
            .map(({ label, path, show }, index) => (
              <Tab
                key={index}
                sx={{
                  fontSize: '18px',
                  fontWeight: 600,
                  '&.MuiButtonBase-root': {
                    outline: 'none',
                  },
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
                component={Link}
                to={path}
                label={show ? label : `${label}*`}
                value={label}
                {...a11yProps(index)}
              />
            ))}
        </Tabs>
      </SectionContainer>
      <SectionContainer backgroundColor="pastel.light" minHeight="80vh">
        <Box paddingY={{ xs: 1, sm: 3 }}>{renderTab()}</Box>
      </SectionContainer>
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const getSelectedTab = (pathname: string) => {
  let selectedTab = TabItem.EXPERTS;
  const selectedTabItem = tabItems.find(
    (tabItem) => tabItem.path.length > 0 && pathname.includes(tabItem.path),
  );
  if (selectedTabItem) {
    selectedTab = selectedTabItem.label;
  }
  return selectedTab;
};

export default ProfileTabs;
