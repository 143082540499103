import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import log from 'loglevel';
import SectionContainer from '../../components/SectionContainer';
import { useEffect, useState } from 'react';
import { EditContentDialog } from '../admin/shared/AdminWidgets';
import { RouterLink } from '../../components/router-link';
import {
  useDeleteContentMutation,
  useGetContentCountForAccount,
  useGetContentForAccount,
} from '../../hooks/content-hooks';
import { useAuth } from '../../hooks/use-auth';
import ConfirmButton from '../shared/ConfirmButton';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ChatOutlined } from '@mui/icons-material';

export default function YourContent() {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const pageCount = 100;

  const [search, setSearch] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [contentId, setContentId] = useState<string>(null);
  const contentQuery = useGetContentForAccount(page, search);
  const {
    data: countData,
    isLoading: contentIsLoading,
    isFetching: contentIsFetching,
  } = useGetContentCountForAccount(search);
  const { user } = useAuth();

  // I used to have an "Ask a question" button. But the code was old
  // and it needs work to show people, so removed it.
  //const [questionText, setQuestionText] = useState<string>('');
  // const questionQuery = useAskAIQuestion(
  //   question,
  //   [user.active_account.account_name],
  //   !!user,
  // );

  useEffect(() => {
    log.debug({ content: contentQuery.data, countData });
  }, [contentQuery.data, countData]);

  const handleSearchClick = () => {
    setSearch(searchText);
  };

  const handleCreateContent = () => {
    setContentId(null);
    setOpen(true);
  };

  const handlePageChange = (_, value: number) => {
    setPage(value);
  };

  if (!user) return <LoadingSpinner />;
  if (countData?.n === 0) return <NoContent />;

  return (
    <SectionContainer sx={{ mt: 2 }}>
      <Stack direction="column" spacing={2} alignItems={'flex-start'}>
        <Button variant="contained" onClick={handleCreateContent}>
          Add Content
        </Button>
        <Stack direction="row" spacing={2}>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            label="Search source for..."
            sx={{ width: 600 }}
          />
          <Button
            variant="contained"
            onClick={handleSearchClick}
            disabled={
              searchText === search || (contentIsLoading && contentIsFetching)
            }
          >
            Search
          </Button>
        </Stack>
      </Stack>

      {open && (
        <EditContentDialog
          content_id={contentId}
          open={open}
          setOpen={setOpen}
        />
      )}
      {contentQuery.data && countData?.n ? (
        <Stack direction="column" sx={{ mt: 2 }}>
          <Pagination
            count={Math.ceil(countData.n / pageCount)}
            page={page}
            onChange={handlePageChange}
          />
          <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 70, textAlign: 'center' }}>
                    #
                  </TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Chunks</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contentQuery.data.map((content) => (
                  <TableRow key={content.document_id}>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <ShowIndex
                        index={content.row}
                        base_id={content.document_id}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 350, fontSize: 12 }}>
                      <RouterLink to={`${content.document_id}`}>
                        {content.source}
                      </RouterLink>
                    </TableCell>
                    <TableCell sx={{ width: 150 }}>{content.chunks}</TableCell>
                    <TableCell>{content.created_at}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : (
        <LoadingSpinner />
      )}
    </SectionContainer>
  );
}

const ShowIndex = ({ index, base_id }) => {
  const [hover, setHover] = useState(false);
  const deleteMutation = useDeleteContentMutation(base_id);
  const handleDelete = () => {
    log.debug('delete', index, base_id);
    deleteMutation.mutate({});
  };

  if (deleteMutation.isLoading)
    return (
      <Box>
        <CircularProgress size={15} />
      </Box>
    );

  return (
    <Box onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
      {hover ? (
        <ConfirmButton isIcon={true} onConfirm={handleDelete} />
      ) : (
        <div>{index}</div>
      )}
    </Box>
  );
};

const NoContent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      mb={2}
    >
      <ChatOutlined sx={{ fontSize: 25, mb: 2 }} />
      <Typography fontWeight={500}>
        You haven't added any content yet.{' '}
      </Typography>
      <Typography>Once you do, you'll see it here.</Typography>
    </Box>
  );
};
