import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useAuth } from 'src/hooks/use-auth';
import AvatarEditorContainer, {
  AvatarData,
} from 'src/components/AvatarEditorContainer';
import {
  useUpdateAccountMutation,
  useUpdateAvatarImageMutation,
} from '../../../hooks/account-hooks';
import log from 'loglevel';

interface EditProfileModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const EditProfileModal = ({ isOpen, setIsOpen }: EditProfileModalProps) => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [shouldOpenAvatarEditor, setShouldOpenAvatarEditor] = useState(false);
  const [removeAvatar, setRemoveAvatar] = useState(false);
  const [avatarData, setAvatarData] = useState<AvatarData>({
    primary: null,
    thumbnail: null,
  });
  const updateProfileMutation = useUpdateAccountMutation();
  const mutateAvatar = useUpdateAvatarImageMutation();

  // Allows us to use the blob as an img src attribute
  const avatarObjectUrl = useObjectUrl(avatarData.primary?.blob);

  const validationSchema = yup.object({
    display_name: yup
      .string()
      .trim()
      .max(200, 'Display name must be 200 characters or less')
      .required(
        'You must enter a display name. It will appear in all your recommendations.',
      ),
    full_name: yup
      .string()
      .trim()
      .max(200, 'Full name must be 200 characters or less'),
    short_desc: yup
      .string()
      .min(10, 'The short description must be at least 10 characters')
      .max(200, 'Maximum of 200 characters')
      .trim(),
    long_desc: yup.string().max(5000, 'Maximum of 5,000 characters.').trim(),
    homepage: yup.string().url('Must be a valid URL').nullable(),
    instagram_url: yup.string().url('Must be a valid URL').nullable(),
    facebook_url: yup.string().url('Must be a valid URL').nullable(),
    tiktok_url: yup.string().url('Must be a valid URL').nullable(),
    youtube_url: yup.string().url('Must be a valid URL').nullable(),
  });

  const formik = useFormik({
    initialValues: {
      avatar_url: user.active_account?.avatar_url || '',
      display_name: user.active_account.display_name || '',
      full_name: user.active_account?.full_name || '',
      short_desc: user.active_account?.short_desc || '',
      long_desc: user.active_account?.long_desc || '',
      homepage: user.active_account?.homepage || '',
      instagram_url: user.active_account?.instagram_url || '',
      facebook_url: user.active_account?.facebook_url || '',
      tiktok_url: user.active_account?.tiktok_url || '',
      youtube_url: user.active_account?.youtube_url || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      log.debug('Submitting form', values);
      if (avatarData.primary) {
        log.debug('Submitting primary avatar');
        await mutateAvatar.mutate(avatarData.primary);
      }
      if (avatarData.thumbnail) {
        log.debug('Submitting thumbnail avatar');
        await mutateAvatar.mutate(avatarData.thumbnail);
      }

      const updatedValues = { ...values };
      if (removeAvatar) {
        // Set to null so that the backend knows to remove the avatar
        updatedValues.avatar_url = null;
      } else {
        // Remove the `avatar_url` property from the values object otherwise it
        // overwrites the prior code and avatar_url gets set to null
        delete updatedValues.avatar_url;
      }

      await updateProfileMutation.mutateAsync(updatedValues, {
        onSuccess: (data) => {
          log.debug('Success', data);
          setIsOpen(false);
          toast.success('Edits saved');
        },
        onError: (error: any) => {
          toast.error(error.message);
        },
        onSettled: () => {
          formik.setSubmitting(false);
        },
      });
    },
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleRemoveImage = () => {
    setRemoveAvatar(true);
    setAvatarData({ primary: null, thumbnail: null });
  };

  const handleOpenEditAvatarModal = () => {
    setRemoveAvatar(false);
    setShouldOpenAvatarEditor(true);
  };

  const AVATAR_SIZE = isMd ? 160 : 80;
  const avatarUrl = removeAvatar
    ? ''
    : avatarObjectUrl || user?.active_account?.avatar_url;

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle>Edit your profile</DialogTitle>
      <DialogContent>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box
            sx={{
              padding: 1,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              onClick={() => handleOpenEditAvatarModal()}
              sx={{
                cursor: 'pointer',
                width: AVATAR_SIZE,
                height: AVATAR_SIZE,
                border: 1,
                borderColor: 'lightgray',
              }}
              src={avatarUrl}
            />
            <Typography fontSize={14}>Click to Edit</Typography>
            {avatarUrl && (
              <IconButton
                sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={handleRemoveImage}
              >
                <CancelIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            {/* Display Name */}
            <FormLabel>Display Name</FormLabel>
            <TextField
              sx={{ width: 1 }}
              id="display_name"
              placeholder="Keep it short"
              size="small"
              disabled={formik.isSubmitting}
              error={
                formik.touched.display_name &&
                Boolean(formik.errors.display_name)
              }
              helperText={
                formik.touched.display_name && formik.errors.display_name
              }
              {...formik.getFieldProps('display_name')}
            />

            {/* Detailed Name */}
            <FormLabel sx={{ mt: 2 }}>Detailed Name</FormLabel>
            <TextField
              sx={{ width: 1 }}
              id="full_name"
              placeholder="Your full name or social handle"
              size="small"
              disabled={formik.isSubmitting}
              error={
                formik.touched.full_name && Boolean(formik.errors.full_name)
              }
              helperText={formik.touched.full_name && formik.errors.full_name}
              {...formik.getFieldProps('full_name')}
            />
          </Box>
        </Stack>
        <Stack
          spacing={4}
          direction="column"
          width={{ xs: 1, sm: 350, md: 500 }}
          mt={2}
        >
          {/* Short Summary */}
          <Box>
            <FormLabel>Short Summary</FormLabel>
            <TextField
              fullWidth={true}
              id="short_desc"
              placeholder="Tell people what types of recommendations you provide"
              size="small"
              disabled={formik.isSubmitting}
              error={
                formik.touched.short_desc && Boolean(formik.errors.short_desc)
              }
              helperText={formik.touched.short_desc && formik.errors.short_desc}
              {...formik.getFieldProps('short_desc')}
            />
          </Box>

          {/* Website */}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignContent="center"
          >
            <FormLabel>Website</FormLabel>
            <TextField
              sx={{ width: isMd ? 0.7 : 1 }}
              id="homepage"
              placeholder="Your website"
              size="small"
              disabled={formik.isSubmitting}
              value={formik.values.homepage ?? ''}
              error={formik.touched.homepage && Boolean(formik.errors.homepage)}
              helperText={formik.touched.homepage && formik.errors.homepage}
              {...formik.getFieldProps('homepage')}
            />
          </Box>

          {/* Instagram */}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignContent="center"
          >
            <FormLabel>Instagram</FormLabel>
            <TextField
              sx={{ width: isMd ? 0.7 : 1 }}
              id="instagram_url"
              placeholder="Instagram profile"
              size="small"
              disabled={formik.isSubmitting}
              error={
                formik.touched.instagram_url &&
                Boolean(formik.errors.instagram_url)
              }
              helperText={
                formik.touched.instagram_url && formik.errors.instagram_url
              }
              {...{
                ...formik.getFieldProps('instagram_url'),
                value: formik.values.instagram_url ?? '',
              }}
            />
          </Box>

          {/* Facebook */}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignContent="center"
          >
            <FormLabel>Facebook</FormLabel>
            <TextField
              sx={{ width: isMd ? 0.7 : 1 }}
              id="facebook_url"
              placeholder="Facebook profile"
              size="small"
              disabled={formik.isSubmitting}
              error={
                formik.touched.facebook_url &&
                Boolean(formik.errors.facebook_url)
              }
              helperText={
                formik.touched.facebook_url && formik.errors.facebook_url
              }
              {...{
                ...formik.getFieldProps('facebook_url'),
                value: formik.values.facebook_url ?? '',
              }}
            />
          </Box>

          {/* TikTok */}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignContent="center"
          >
            <FormLabel>TikTok</FormLabel>
            <TextField
              sx={{ width: isMd ? 0.7 : 1 }}
              id="tiktok_url"
              placeholder="TikTok profile"
              size="small"
              disabled={formik.isSubmitting}
              error={
                formik.touched.tiktok_url && Boolean(formik.errors.tiktok_url)
              }
              helperText={formik.touched.tiktok_url && formik.errors.tiktok_url}
              {...{
                ...formik.getFieldProps('tiktok_url'),
                value: formik.values.tiktok_url ?? '',
              }}
            />
          </Box>

          {/* YouTube */}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignContent="center"
          >
            <FormLabel>YouTube</FormLabel>
            <TextField
              sx={{ width: isMd ? 0.7 : 1 }}
              id="youtube_url"
              placeholder="YouTube profile"
              size="small"
              disabled={formik.isSubmitting}
              error={
                formik.touched.youtube_url && Boolean(formik.errors.youtube_url)
              }
              helperText={
                formik.touched.youtube_url && formik.errors.youtube_url
              }
              {...{
                ...formik.getFieldProps('youtube_url'),
                value: formik.values.youtube_url ?? '',
              }}
            />
          </Box>

          {/* About */}
          <Box>
            <FormLabel>About</FormLabel>
            <TextField
              fullWidth={true}
              id="long_desc"
              placeholder="Share more details about yourself and your recommendations"
              size="small"
              multiline
              minRows={5}
              disabled={formik.isSubmitting}
              error={
                formik.touched.long_desc && Boolean(formik.errors.long_desc)
              }
              helperText={formik.touched.long_desc && formik.errors.long_desc}
              {...formik.getFieldProps('long_desc')}
            />
          </Box>
        </Stack>
        <AvatarEditorContainer
          account={user.active_account}
          open={shouldOpenAvatarEditor}
          setOpen={setShouldOpenAvatarEditor}
          avatarData={avatarData}
          setAvatarData={setAvatarData}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => formik.submitForm()}
          form="profile-form"
          disabled={formik.isSubmitting || !formik.isValid}
          loading={formik.isSubmitting}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const FormLabel = styled(Typography)({
  fontSize: 17,
  fontWeight: 600,
  lineHeight: '30px',
});

export default EditProfileModal;

function useObjectUrl(blob?: Blob): string | undefined {
  const [objectUrl, setObjectUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!blob) {
      setObjectUrl(undefined);
      return undefined;
    }

    const url = URL.createObjectURL(blob);
    setObjectUrl(url);

    // Cleanup when the component unmounts or the blob changes
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [blob]);

  return objectUrl;
}
