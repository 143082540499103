import {
  Box,
  Card,
  Stack,
  Typography,
  Skeleton,
  Grid,
  Button,
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import ProductTileVector from 'src/pages/extension/search/ProductTileVector';
import { ProductTileContext } from '../../../types/product_context';
import { useGetAllFavoritedProducts } from 'src/hooks/favorite-product-hooks';

interface RecentlySavedItemsProps {
  isDashboard?: boolean;
}

/**
 *
 * @param {*} param0
 * @returns
 */
const RecentlySavedItems = ({
  isDashboard = false,
}: RecentlySavedItemsProps) => {
  const navigate = useNavigate();
  const titleRefs = useRef([]);

  const { data: favoriteQuery, isLoading } = useGetAllFavoritedProducts();

  useEffect(() => {
    let maxHeight = 0;
    titleRefs.current.forEach((ref) => {
      if (ref?.clientHeight) {
        maxHeight = Math.max(maxHeight, ref.clientHeight);
      }
    });

    titleRefs.current.forEach((ref) => {
      ref.style.height = `${maxHeight}px`;
    });
  }, [favoriteQuery]);

  const redirectToSavedItems = () => {
    navigate('/profile/saves');
  };

  const NUMBER_OF_SAVED_ITEMS_TO_SHOW = isDashboard ? 3 : 100;

  const Title = isDashboard && (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={24} fontWeight={600}>
        Recently saved
      </Typography>
      {favoriteQuery &&
        favoriteQuery.results?.length > NUMBER_OF_SAVED_ITEMS_TO_SHOW && (
          <Button
            component="label"
            size="small"
            color="primary"
            sx={{
              fontSize: 18,
              fontWeight: 600,
              '&:hover': { backgroundColor: 'transparent' },
            }}
            onClick={redirectToSavedItems}
            endIcon={<ArrowForwardIosOutlinedIcon />}
          >
            See all
          </Button>
        )}
    </Box>
  );

  const ProductTileLoader = (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        overflow: 'auto',
      }}
    >
      {Array.from({
        length:
          NUMBER_OF_SAVED_ITEMS_TO_SHOW > 3 ? 4 : NUMBER_OF_SAVED_ITEMS_TO_SHOW,
      }).map((_, index) => (
        <Box key={index} sx={{ mr: 2, height: 400 }}>
          <Card
            sx={{
              height: '100%',
              width: 289,
              p: 1,
            }}
          >
            <Skeleton
              variant="rounded"
              animation="wave"
              height="50%"
              sx={{ mb: 1 }}
            />
            <Stack direction="row" spacing={1} marginBottom={2}>
              <Skeleton
                variant="circular"
                animation="wave"
                width={40}
                height={40}
              />
              <Stack direction="column" width="80%">
                <Skeleton animation="wave" height="100%" width="100%" />
                <Skeleton animation="wave" height="100%" width="100%" />
              </Stack>
            </Stack>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton animation="wave" width="100%" key={index} />
            ))}
          </Card>
        </Box>
      ))}
    </Box>
  );

  const EmptySavedItems = (
    <Card
      sx={{
        height: '214px',
        width: '100%',
        p: 2,
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height={1}
      >
        <FavoriteBorderIcon
          color="primary"
          sx={{ fontSize: '80px', mb: { xs: 1, sm: 3 } }}
        />
        <Typography fontSize="19px" textAlign="center">
          You haven't saved any products yet! Tap on the heart icon to save
          products for later.
        </Typography>
      </Box>
    </Card>
  );

  return (
    <Stack direction="column">
      {Title}

      <Box sx={{ mt: 2 }}>
        {isLoading ? (
          ProductTileLoader
        ) : favoriteQuery?.results?.length === 0 ? (
          EmptySavedItems
        ) : (
          <SavedItems
            isDashboard={isDashboard}
            favoriteQuery={favoriteQuery}
            numberItemsToShow={NUMBER_OF_SAVED_ITEMS_TO_SHOW}
          />
        )}
      </Box>
    </Stack>
  );
};

const SavedItems = ({ isDashboard, favoriteQuery, numberItemsToShow }) => {
  if (isDashboard) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row nowrap',
          overflow: 'auto',
        }}
      >
        {favoriteQuery?.results
          .slice(0, numberItemsToShow)
          .map((item, index) => (
            <Box key={item.product.product_id} sx={{ mr: 2 }}>
              <ProductTileVector
                searchItem={item}
                newTab={false}
                context={ProductTileContext.WEBSITE}
              />
            </Box>
          ))}
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {favoriteQuery?.results.slice(0, numberItemsToShow).map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item.product.product_id}>
          <ProductTileVector
            searchItem={item}
            context={ProductTileContext.WEBSITE}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default RecentlySavedItems;
