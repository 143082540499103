export interface Account {
  account_id: string;
  referrer_account_name?: string;
  created_by?: string;
  account_type?: AccountType;
  account_name: string;
  display_name?: string;
  full_name?: string;
  join_type?: JoinType;
  account_status?: AccountStatus;
  avatar_url?: string;
  short_desc?: string;
  long_desc?: string;
  homepage?: string;
  descriptor?: string;
  instagram_url?: string;
  facebook_url?: string;
  tiktok_url?: string;
  youtube_url?: string;
  permission_created_by?: string;
  account_user_status?: string;
  user_role?: string;
  avatar_url_tn?: string;
  created_at?: string;
  updated_at?: string;
  trusted_accounts?: {
    account_id: string;
    account_name: string;
    avatar_url?: string;
    avatar_url_tn?: string;
    created_at: string;
    display_name: string;
  }[];
  preview?: PreviewType;
  values?: Array<string>;
  my_values?: Array<string>;
  my_account_types?: Array<string>;
  ai_ingredients_type: AIIngredientsType;
  ai_ingredients_settings?: string;
  ai_products_type: AIProductsType;
  ai_products_settings?: string;
}

export enum AIIngredientsType {
  NONE = 'not_supported',
  CONTENT_LIBRARY = 'content_library',
  WEB_SEARCHER = 'web_searcher',
}

export enum AIProductsType {
  NONE = 'not_supported',
  CONTENT_LIBRARY = 'content_library',
  WEB_SEARCHER = 'web_searcher',
}

/**
 * Information about an account trusted by the current user
 */
export interface TrustedAccount {
  account_id: string;
  account_type?: AccountType;
  account_name: string;
  display_name: string;
  avatar_url?: string;
  avatar_url_tn?: string;
  short_desc?: string;
  long_desc?: string;
  homepage?: string;
  updated_at?: string;
  full_name?: string;
  values?: string[];
}

export interface Tag {
  tag: string;
  n: number;
}

export interface AccountProductCategoryImage {
  image_url: string;
}

export interface AccountProductCategory {
  tag: string;
  representative_images: AccountProductCategoryImage[];
}

export enum PreviewType {
  BETA = 'beta',
}

export enum AccountType {
  PERSONAL = 'personal',
  GROUP = 'group',
  CERTIFIER = 'certifier',
  CREATOR = 'creator',
  MEDIA_PUBLICATION = 'media_publication',
  RETAILER = 'retailer',
  BRAND = 'brand',
  WATCHDOG_AGENCY = 'watchdog_agency',
  MEDICAL_PROVIDER = 'medical_provider',
}

export enum JoinType {
  CLOSED = 'closed',
  OPEN = 'open',
}

export enum AccountStatus {
  ACTIVE = 'active',
  HIDDEN = 'hidden',
  CANCELLED = 'cancelled',
}

export type AvatarItem = {
  avatar_url: string | null;
  display_name?: string;
};

export type CreatorAccountItem = {
  account_id: string;
  account_name?: string;
  avatar_url: string | null;
  display_name?: string;
};
