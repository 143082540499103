import { Box, Button, Grid, Typography } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { useGetTrustedAccountsQuery } from 'src/hooks/trusted-accounts-hooks';
import { LoadingSpinner } from 'src/components/loading-spinner';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import OnboardingExpertSelector from 'src/pages/onboarding/components/OnboardingExpert';
import { useEffect, useRef, useState } from 'react';

export default function TrustedExperts() {
  const { user } = useAuth();
  const account = user?.active_account;
  const { data } = useGetTrustedAccountsQuery();

  if (!data) return <LoadingSpinner />;

  const trustedExperts = data.filter(
    (expert) => expert.account_id !== account.account_id,
  );

  if (trustedExperts.length === 0) return <NoTrustedAccounts />;

  return (
    <OnboardingExpertCarousel
      accountNames={trustedExperts.map((expert) => expert.account_name)}
    />
  );
}

type OnboardingExpertCarouselProps = {
  accountNames: string[];
  autoSelect?: boolean;
};
const OnboardingExpertCarousel = ({
  accountNames,
}: OnboardingExpertCarouselProps) => {
  // This will store the first non empty account names so that when somebody
  // clicks the "Counting On" button (to stop counting on them), they don't
  // disappear from the list, at least until the page refreshes. This makes it
  // easy to change your mind.
  const firstNonEmptyAccountNames = useRef(null);
  const [savedAccountNames, setSavedAccountNames] = useState(null);

  useEffect(() => {
    // Set the first non-empty accountNames if not already set
    if (
      !firstNonEmptyAccountNames.current &&
      accountNames &&
      accountNames.length > 0
    ) {
      firstNonEmptyAccountNames.current = accountNames;
      setSavedAccountNames(accountNames);
    }
  }, [accountNames]);

  if (!savedAccountNames) return null;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button component={Link} to="/search/experts" variant="contained">
          Find more experts!
        </Button>
      </Box>
      <Grid
        container
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        spacing={1}
        wrap="wrap"
        width={1}
        maxWidth={1025}
        sx={{
          mx: 'auto',
          py: 2,
        }}
      >
        {savedAccountNames.map((accountName) => (
          <Grid item key={accountName}>
            <OnboardingExpertSelector
              accountName={accountName}
              autoSelect={false}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const NoTrustedAccounts = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      mb={2}
    >
      <PersonIcon sx={{ fontSize: 25, mb: 2 }} />
      <Typography fontWeight={500}>
        You are not counting on any experts yet.
      </Typography>
      <Typography
        fontWeight={500}
        color="secondary.main"
        component={Link}
        to="/search/experts"
      >
        Find some experts to Count On.
      </Typography>
    </Box>
  );
};
