import { useState, useRef, Dispatch, SetStateAction, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AvatarEditor from 'react-avatar-editor';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { AvatarImg } from 'src/hooks/account-hooks';
import { Account } from '../types/account';

export type AvatarData = {
  primary: AvatarImg | null;
  thumbnail: AvatarImg | null;
};

type AvatarEditorContainerProps = {
  account: Account;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  avatarData: AvatarData;
  setAvatarData: Dispatch<SetStateAction<AvatarData>>;
};

export default function AvatarEditorContainer({
  account,
  open,
  setOpen,
  avatarData,
  setAvatarData,
}: AvatarEditorContainerProps) {
  const [scale, setScale] = useState(1.2);
  const file = useRef<File>(null);
  const editor = useRef<AvatarEditor>(null);
  const tnEditor = useRef<AvatarEditor>(null);
  const [pos, setPos] = useState();

  // A little janky, but this will close the dialog when the primary and
  // thumbnail images have been generated and returned to the parent component
  useEffect(() => {
    if (!file.current) return;
    if (avatarData.primary && avatarData.thumbnail) {
      setOpen(false);
    }
  }, [file, avatarData, setOpen]);

  const handleAccept = () => {
    // When zooming out, the image can end up with black areas. This changes
    // that to white, which works better with most logos.
    const drawCanvasWithBackground = (
      canvas: HTMLCanvasElement,
    ): HTMLCanvasElement => {
      const ctx = canvas.getContext('2d');
      if (!ctx) return canvas;

      // Create a temporary canvas to draw the white background
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;

      const tempCtx = tempCanvas.getContext('2d');
      if (tempCtx) {
        // Fill the background with white
        tempCtx.fillStyle = 'white';
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

        // Draw the original canvas image on top of the white background
        tempCtx.drawImage(canvas, 0, 0);
      }

      return tempCanvas;
    };

    const canvasScaled: HTMLCanvasElement = drawCanvasWithBackground(
      editor.current.getImageScaledToCanvas(),
    );
    const tnCanvasScaled: HTMLCanvasElement = drawCanvasWithBackground(
      tnEditor.current.getImageScaledToCanvas(),
    );

    const objName = `${Date.now()}-${account.account_id}`;

    // This is the large image displayed on profile pages
    canvasScaled.toBlob(
      (blob) => {
        if (blob) {
          setAvatarData((prev) => ({
            ...prev,
            primary: { blob, filename: `${objName}.jpg`, useAsAvatarUrl: true },
          }));
        }
      },
      'image/jpeg',
      1,
    );

    // This is the thumbnail used in recommendations
    tnCanvasScaled.toBlob(
      (blob) => {
        if (blob) {
          setAvatarData((prev) => ({
            ...prev,
            thumbnail: {
              blob,
              filename: `tn32x32-${objName}.jpg`,
              useAsAvatarUrl: false,
            },
          }));
        }
      },
      'image/jpeg',
      1,
    );
  };

  const handleZoom = (dir) => {
    if (dir === 'in') {
      setScale(scale * 1.1);
    } else if (dir === 'out') {
      setScale(Math.max(scale * 0.9, 0.8));
    }
  };

  const chooseImageFile = (e) => {
    file.current = e.target.files[0];
    setScale(1.1);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Choose you Avatar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a jpg or png image and then zoom and position however you like.
          The small image is roughly the size people will see on products you
          recommend.
        </DialogContentText>

        <Box sx={{ mt: 2, mx: 'auto', width: 1 }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            sx={{ justifyContent: 'center' }}
          >
            {/* https://github.com/mosch/react-avatar-editor */}
            <Paper elevation={3} sx={{ position: 'relative' }}>
              <Stack
                direction="column"
                spacing={0}
                sx={{ position: 'absolute', top: 0, left: 0 }}
              >
                <IconButton
                  aria-label="zoom in"
                  onClick={() => handleZoom('in')}
                >
                  <ZoomInIcon />
                </IconButton>
                <IconButton
                  aria-label="zoom out"
                  disabled={scale === 0.8}
                  onClick={() => handleZoom('out')}
                >
                  <ZoomOutIcon />
                </IconButton>
              </Stack>

              <AvatarEditor
                ref={editor}
                image={file.current}
                width={300}
                height={300}
                border={5}
                borderRadius={180}
                color={[255, 255, 255, 0.6]} // RGBA
                backgroundColor="white"
                scale={scale}
                rotate={0}
                onPositionChange={(newPos) => setPos(newPos)}
              />
            </Paper>
            <AvatarEditor
              ref={tnEditor}
              image={file.current}
              width={32}
              height={32}
              border={0}
              borderRadius={180}
              color={[255, 255, 255, 0.9]} // RGBA
              scale={scale}
              rotate={0}
              position={pos}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onChange={chooseImageFile} component="label">
          Choose File...
          <input type="file" accept="image/*" hidden />
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleAccept} disabled={!file.current}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
