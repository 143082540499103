import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import logoUrl from '../../../assets/logos/CountOn_primary.png';
import { Divider, MenuItem, MenuList } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { ProfileSelector } from './ProfileSelector';
import AddExtensionButton from 'src/pages/home/components/AddExtensionButton';
import { isChrome, isMobileSafari } from 'react-device-detect';

const SidebarNav = ({ onClose }) => {
  const { isAuthenticated, isExtInstalled } = useAuth();
  const isSafariExtInstalled = false;

  const showExtensionButton =
    (isMobileSafari && !isSafariExtInstalled) || (isChrome && !isExtInstalled);

  const MyMenuItem = ({ to, children, sx = {}, onClick = onClose }) => {
    return (
      <MenuItem
        sx={{ ...sx, fontWeight: 700 }}
        component={Link}
        to={to}
        onClick={onClick}
      >
        {children}
      </MenuItem>
    );
  };

  // Apologies for the goofy code duplication. Mui doesn't like items inside a
  // fragment so trying to avoid repeating the items common to both users and
  // guests proved more trouble than it was worth
  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component={Link}
          to="/"
          title="CountOn"
          width={{ xs: 100, md: 120 }}
        >
          <Box component={'img'} src={logoUrl} height={60} onClick={onClose} />
        </Box>
      </Box>
      <Box>
        {isAuthenticated ? (
          <MenuList>
            <MyMenuItem to="faq">FAQ</MyMenuItem>
            <Divider />
            <ProfileSelector onChange={onClose} />
            <Divider sx={{ my: 1 }} />
            {showExtensionButton && (
              <AddExtensionButton
                gtmParams={{ location: 'sidebar' }}
                sx={{ px: 2, height: 42 }}
                onClick={onClose}
              />
            )}
          </MenuList>
        ) : (
          <MenuList>
            <MyMenuItem to="faq">FAQs</MyMenuItem>
            <Divider />
            <MyMenuItem to="login" sx={{ color: 'primary.main' }}>
              Login
            </MyMenuItem>
            <MyMenuItem
              sx={{
                backgroundColor: 'primary.main',
                color: 'white.main',
              }}
              to="signup"
            >
              Sign Up
            </MyMenuItem>
          </MenuList>
        )}
      </Box>
    </Box>
  );
};

export default SidebarNav;
