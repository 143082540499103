import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Avatar,
  Badge,
  Box,
  CardMedia,
  Chip,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import log from 'loglevel';
import { Link } from 'react-router-dom';
// import { regenLinkData } from 'src/pages/shared/affiliateLinks';
import { useSegment } from 'src/contexts/segment-context';
import FavoriteProductButton from 'src/pages/shared/FavoriteProductButton';
import { useLayoutEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useGetRatingSystems } from 'src/hooks/rating-system-hooks';
import CountOnAvatar from 'src/components/avatar/CountOnAvatar';
import {
  VectorProductSearchItem,
  VectorSearchReviewItem,
} from 'src/types/search';
import { ProductTileContext } from 'src/types/product_context';
import { getImage } from 'src/lib/searchDataConversion';
import { ReviewType } from '../../../types/reviews';
import { BuyButton } from '../../shared/BuyButton';

interface ProductTileProps {
  searchItem: VectorProductSearchItem;
  height?: number;
  width?: number;
  newTab?: boolean;
  context?: ProductTileContext;
  site?: string;
  primaryAccountId?: string;
  search_id?: string;
}

export default function ProductTileVector({
  searchItem,
  height = 610,
  width = 290,
  newTab = false,
  context = ProductTileContext.DEFAULT,
  site = null,
  primaryAccountId = null,
  search_id = null,
}: ProductTileProps) {
  const [selectedAccountId, setSelectedAccountId] = useState<string>(null);
  const imageUrl = getImage(searchItem?.product?.images, primaryAccountId);
  const inExtension = context === ProductTileContext.EXTENSION;

  let itemsToShow =
    searchItem.trusted.reviews.length > 0
      ? searchItem.trusted.reviews
      : searchItem.community.reviews;

  // If a primaryAccountId is supplied, we need to show it first, regardless of if
  // it's a trusted or community review.
  if (primaryAccountId) {
    let primaryAccount = [
      ...searchItem.trusted.reviews,
      ...searchItem.community.reviews,
    ].find((r) => r.account_id === primaryAccountId);
    if (primaryAccount)
      itemsToShow = [
        primaryAccount,
        ...itemsToShow.filter((r) => r.account_id !== primaryAccountId),
      ];
  }

  const getAnalyticsData = () => ({
    // If context is EXTENSION, switch to SEARCH_CAROUSEL for analytics
    context: inExtension ? ProductTileContext.SEARCH_CAROUSEL : context,
    extension: inExtension,
    site: site,
    product_id: searchItem.product.product_id,
    title: searchItem.product.title,
    trusted_experts: searchItem.trusted.reviews.map((r) => r.account_name),
    community_experts: searchItem.community.reviews.map((r) => r.account_name),
  });

  // TODO: This ProductTile will likely end up being used outside the extension. This
  // code will need to be updated then.
  // const linkData = regenLinkData(details.url, details.image_url, context);
  const detailPageUrl = `/shop/${searchItem.product.product_id}${
    primaryAccountId
      ? `/${primaryAccountId}`
      : selectedAccountId
      ? `/${selectedAccountId}`
      : ''
  }${search_id ? '?sid=' + search_id : ''}`;

  return (
    <Card
      sx={{
        p: 0,
        boxShadow: '1px 1px 4px 0px #22223D40',
        width: { xs: 0.98, sm: width },
        height: height,
        minWidth: width,
      }}
      variant="outlined"
    >
      <Stack direction="column" sx={{ display: 'flex', height: 1 }}>
        <Box sx={{ position: 'relative' }}>
          <ProductCardMedia
            image_url={imageUrl}
            navigateTo={detailPageUrl}
            newTab={newTab}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              backgroundColor: 'rgba(255,255,255,0.75)',
              borderRadius: 2,
            }}
          >
            <FavoriteProductButton
              title={searchItem.product.title}
              product_id={searchItem.product.product_id}
              analyticsData={getAnalyticsData()}
            />
          </Box>
        </Box>
        <CardContent sx={{ flexGrow: 1 }}>
          <Stack
            spacing={1}
            direction="column"
            height={1}
            sx={{ display: 'flex' }}
          >
            {/* Product Title */}
            <Typography
              component={Link}
              to={detailPageUrl}
              target={newTab ? '_blank' : undefined}
              rel={newTab ? 'noreferrer' : undefined}
              sx={{
                fontSize: 16,
                lineHeight: '21px',
                fontWeight: 600,
                color: 'black.main',
                overflow: 'hidden',
                height: 50,
                textDecoration: 'none',
              }}
            >
              {searchItem.product.title}
            </Typography>
            {/* Trusted Recommendations */}
            {itemsToShow.length > 0 && (
              <ShownRatings
                ratings={itemsToShow}
                dpUrl={detailPageUrl}
                newTab={newTab}
                onRatingSelect={setSelectedAccountId}
                context={context}
                primaryAccountId={primaryAccountId}
              />
            )}
          </Stack>
        </CardContent>
        <CardActions sx={{ px: 2, pb: 2, pt: 0 }}>
          {inExtension ? (
            <CountOnDPButton
              product_id={searchItem.product.product_id}
              source={inExtension && ProductTileContext.EXTENSION}
              analyticsData={getAnalyticsData()}
            />
          ) : (
            <BuyButton
              offers={searchItem.offers}
              analyticsData={getAnalyticsData()}
              attributionData={{
                trusted_review_ids: searchItem.trusted.reviews.map(
                  (r) => r.review_id
                ),
                community_review_ids: searchItem.community.reviews.map(
                  (r) => r.review_id
                ),
              }}
              primary_account_id={primaryAccountId}
              search_id={search_id}
            />
          )}
        </CardActions>
      </Stack>
    </Card>
  );
}

const CountOnDPButton = ({
  cta = 'Learn More',
  product_id,
  primaryAccountId = null,
  analyticsData = {},
  source = null,
}) => {
  const segment = useSegment();
  const detailPageUrl = `/shop/${product_id}${
    primaryAccountId ? `/${primaryAccountId}` : ''
  }${source ? `?source=${source}` : ''}`;

  const handleClick = () => {
    log.info('CountOn DP Button Clicked', { product_id, primaryAccountId });
    segment.track('CTA Clicked', {
      primary_account_id: primaryAccountId ? primaryAccountId : undefined,
      cta,
      ...analyticsData,
    });
  };

  return (
    <Button
      sx={{
        width: 1,
        fontSize: '18px',
        fontWeight: 600,
        border: '2px solid',
        '&.MuiButton': {
          outline: 'none',
        },
        '&:hover': {
          border: '2px solid',
          color: 'primary.contrastText',
        },
      }}
      disabled={!product_id}
      variant="contained"
      endIcon={<LaunchIcon />}
      href={detailPageUrl}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      {cta}
    </Button>
  );
};

const ProductCardMedia = ({ sx = [], image_url, navigateTo, newTab }) => {
  if (!image_url)
    return (
      <Box
        component={Link}
        to={navigateTo}
        target={newTab ? '_blank' : undefined}
        rel={newTab ? 'noreferrer' : undefined}
        sx={[
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '22px',
            height: 200,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        Image coming soon!
      </Box>
    );
  return (
    <CardMedia
      image={image_url}
      component={Link}
      to={navigateTo}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noreferrer' : undefined}
      sx={[
        {
          backgroundSize: 'contain',
          height: 200,
          cursor: navigateTo ? 'pointer' : undefined,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

const ShownRatings = ({
  ratings,
  maxShown = 3,
  dpUrl = '/',
  newTab = false,
  onRatingSelect = (a: string) => {},
  context = ProductTileContext.DEFAULT,
  primaryAccountId = null,
}: {
  ratings: VectorSearchReviewItem[];
  maxShown?: number;
  dpUrl?: string;
  newTab?: boolean;
  onRatingSelect?: (a: string) => void;
  context?: ProductTileContext;
  primaryAccountId?: string;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [valueOverflow, setValueOverflow] = useState(false);
  const [detailsOverflow, setDetailsOverflow] = useState(false);
  const segment = useSegment();

  //const combinedRatings = [] as VectorSearchReviewItem[];
  //ratings.sort(sortByReviewType).filter(filterGetFirstPerAccount)
  const combinedRatings = [...ratings]
    .sort((a, b) => {
      if (
        a.account_id === primaryAccountId ||
        b.account_id === primaryAccountId
      )
        return a.account_id === primaryAccountId ? -1 : 1;

      if (a.review_type !== b.review_type)
        return a.review_type === ReviewType.STANDARD ? -1 : 1;
      return 0; // Could add 'is an active filter' here to move those to the top
    })
    .filter((item, index, self) => {
      return index === self.findIndex((t) => t.account_id === item.account_id);
    });
  //log.info({ ratings, combinedRatings });

  const handleAvatarClick = (index: number) => {
    setActiveIndex(index);
    onRatingSelect(ratings[index].account_id);
    segment.track('Avatar Clicked', {
      context: context,
      category: 'product_tile_rec',
      account_id: combinedRatings[index].account_id,
      account_name: combinedRatings[index].account_name,
      review_id: combinedRatings[index].review_id,
    });
  };
  if (combinedRatings.length === 0) return null;
  const rec = combinedRatings[activeIndex];
  const isSingleRating = combinedRatings.length === 1;

  // TODO: The move to separate reviews and products means more than one review from the same
  // expert could apply to a single product. Consider a brand level review and a product
  // specific review. For now, I just changed the <AvatarTab> key to include the index, but
  // long term we should consider how to handle this.
  return (
    <Stack direction="column" height={1}>
      <Stack direction="row">
        {!isSingleRating &&
          combinedRatings
            .slice(0, maxShown)
            .map((rating, i) => (
              <AvatarTab
                key={rating.account_id + '-' + i}
                ratingLevel={rating.rating_level}
                active={activeIndex === i}
                onClick={() => handleAvatarClick(i)}
                avatar_url={rating.avatar_url}
                text={rating.display_name}
              />
            ))}
        <Box sx={{ flexGrow: 1 }} />
        {combinedRatings.length > maxShown && (
          <Box
            component={Link}
            to={dpUrl}
            target={newTab ? '_blank' : undefined}
            rel={newTab ? 'noreferrer' : undefined}
          >
            <AvatarTab
              active={activeIndex === maxShown + 1}
              onClick={() => log.trace('plus tab clicked')}
              text={`+${combinedRatings.length - maxShown}`}
            />
          </Box>
        )}
      </Stack>
      <Stack
        direction="column"
        spacing={1}
        position="relative"
        sx={{
          backgroundColor: rec.rating_level > 0 ? 'pastel.purple' : '#FBE8DA',
          borderRadius: `${
            activeIndex === 0 && !isSingleRating ? 0 : 8
          }px 8px 8px 8px`,
          pt: 1,
          px: 1,
          flexGrow: 1,
        }}
      >
        <RatingHeader
          avatar_url={isSingleRating ? rec.avatar_url : undefined}
          account_name={rec.account_name}
          name={rec.display_name}
          level={rec.rating_level || 1}
          ratingSystemId={rec.rating_system_id}
          reviewType={rec.review_type}
          accountType={rec.account_type}
        />
        <RecommendationText
          text={rec.details}
          brandReview={rec['brand_review']}
          setOverflow={setDetailsOverflow}
          isPositive={rec.rating_level > 0}
        />
        <ProductTileValueCarousel
          values={rec.hash_values}
          onOverflow={() => setValueOverflow(true)}
        />
        {(valueOverflow || detailsOverflow) && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 2,
              right: 8,
              pl: 3,
              background: `linear-gradient(270deg, ${
                rec.rating_level > 0 ? '#EEEBFD' : '#FBE8DA'
              } 80.06%, rgba(238, 235, 253, 0) 100%)`,
            }}
          >
            <Button
              component={Link}
              to={dpUrl}
              target={newTab ? '_blank' : undefined}
              rel={newTab ? 'noreferrer' : undefined}
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                fontWeight: 600,
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              More
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

const RecommendationText = ({
  text,
  brandReview,
  setOverflow = (a: boolean) => {},
  isPositive,
}: {
  text: string;
  brandReview?: VectorSearchReviewItem;
  setOverflow;
  isPositive: boolean;
}) => {
  const ref = useRef<HTMLDivElement>();
  const [isOverflowed, setIsOverflowed] = useState(false);
  const markdownStyle = { fontSize: '13px' };

  useLayoutEffect(() => {
    if (!ref.current) return;
    if (ref.current.clientHeight < ref.current.scrollHeight) {
      setOverflow(true);
      setIsOverflowed(true);
    } else {
      setOverflow(false);
      setIsOverflowed(false);
    }
  }, [text, ref.current?.clientHeight, setOverflow]);

  // There is strange behavior with flexgrids and overflow. See
  // https://stackoverflow.com/questions/34154349/flexbox-and-overflow-hidden-not-working-properly
  return (
    <Box sx={{ position: 'relative', flexGrow: 1, flexBasis: 0, minHeight: 0 }}>
      <Box ref={ref} sx={{ height: 1, overflow: 'hidden', textWrap: 'wrap' }}>
        <Box
          sx={{
            '& div.markdown': markdownStyle,
            '& p:first-of-type': { marginTop: 0 },
            '& p:last-child': { marginBottom: 0 },
          }}
        >
          <div>
            <ReactMarkdown className="markdown" linkTarget="_blank">
              {text}
            </ReactMarkdown>
            {brandReview && (
              <Stack direction="column" spacing={0.5}>
                <BrandReviewNotice />
                <ReactMarkdown className="markdown" linkTarget="_blank">
                  {brandReview.details}
                </ReactMarkdown>
              </Stack>
            )}
          </div>
        </Box>
      </Box>
      {isOverflowed && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: 1,
            height: 25,
            background: `linear-gradient(0deg, ${
              isPositive ? '#EEEBFD' : '#FBE8DA'
            } 0%, rgba(238, 235, 253, 0) 100%)`,
          }}
        />
      )}
    </Box>
  );
};

interface RatingHeaderProps {
  name: string;
  account_name: string;
  level: number;
  avatar_url?: string;
  ratingSystemId: string;
  reviewType?: string;
  accountType?: string;
  sx?: SxProps<Theme>;
}
export const RatingHeader = ({
  avatar_url,
  name,
  account_name,
  level,
  ratingSystemId,
  reviewType = 'standard',
  accountType = 'creator',
  sx = [],
}: RatingHeaderProps) => {
  const { data: allRatingSystems } = useGetRatingSystems();
  const ratingSystem = allRatingSystems?.find(
    (s) => s.rating_system_id === ratingSystemId
  );
  const levelDetail = ratingSystem?.levels?.find((lev) => lev.level === level);

  let recVerb = level > 0 ? 'Recommended' : 'Not Recommended';
  let recVerbColor = level > 0 ? '#37A99E' : '#FE654F';
  if (accountType === 'certification') recVerb = 'Certified';
  if (ratingSystemId) {
    recVerb = levelDetail?.name;
    recVerbColor = levelDetail?.color || recVerbColor;
  }

  const handleProfileNameClick = () => {
    window.open('/p/' + account_name, '_blank');
  };

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        {avatar_url && <CountOnAvatar url={avatar_url} text={name} />}
        <Box sx={{ lineHeight: '18px' }}>
          <Typography
            component="span"
            onClick={handleProfileNameClick}
            sx={[
              {
                cursor: 'pointer',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                fontWeight: 600,
                fontSize: 14,
              },
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          >
            {name}
          </Typography>
          {avatar_url && <br />}
          <Typography
            component="span"
            sx={[
              {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: recVerbColor,
                fontWeight: 600,
                fontSize: 14,
              },
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          >
            {' ' + recVerb}
          </Typography>
        </Box>
      </Stack>
      {reviewType === 'brand' && <BrandReviewNotice />}
    </Box>
  );
};

const BrandReviewNotice = () => {
  return (
    <Stack direction="row" spacing={0.5} alignItems={'center'} sx={{ mt: 0.5 }}>
      <InfoIcon color="primary" sx={{ fontSize: 16 }} />
      <Typography fontWeight={600} fontSize={14} color="primary">
        Brand Review
      </Typography>
    </Stack>
  );
};

const AvatarTab = ({
  active,
  onClick,
  avatar_url = null,
  ratingLevel = 1,
  text,
}) => {
  return (
    <Box
      sx={{
        height: 60,
        width: 60,
        display: 'flex',
        alignItems: 'center',
        backgroundColor:
          active && (ratingLevel > 0 ? 'pastel.purple' : '#FBE8DA'),
        borderRadius: '8px 8px 0px 0px',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={
          ratingLevel < 0 ? (
            <Avatar
              sx={{
                backgroundColor: '#E27602',
                border: '2px solid white',
                height: 20,
                width: 20,
              }}
            >
              <ErrorOutlineIcon fontSize="inherit" />
            </Avatar>
          ) : (
            0
          )
        }
        sx={{ mx: 'auto' }}
      >
        <CountOnAvatar url={avatar_url} text={text} />
      </Badge>
    </Box>
  );
};

// function CommunityRatings({ ratings }) {
//   if (ratings.length === 0) return null;
//   return (
//     <Box>
//       <Typography fontSize={14}>
//         (And {ratings.length} community rating
//         {ratings.length > 1 && 's'})
//       </Typography>
//     </Box>
//   );
// }

interface ProductTileValueCarouselProps {
  values: string[];
  highlightedValues?: string[];
  onOverflow?: () => void;
}
const ProductTileValueCarousel = ({
  values,
  highlightedValues = [],
  onOverflow = () => {},
}: ProductTileValueCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (!carouselRef.current) return;
    if (carouselRef.current.clientWidth < carouselRef.current.scrollWidth) {
      onOverflow();
    }
  }, [carouselRef, onOverflow]);

  if (!(values?.length > 0)) return null;
  const highlightedLc = highlightedValues.map((x) => x.toLowerCase());

  const sxChip = (value) => {
    return highlightedLc.includes(value.toLowerCase())
      ? { bgcolor: '#BEB4F980', color: 'primary.main' }
      : { bgcolor: '#FFFFFF', color: 'primary.main' };
  };

  const sortedValues = [...values].sort((a, b) => {
    const aIsHighlighted = highlightedLc.includes(a.toLowerCase());
    const bIsHighlighted = highlightedLc.includes(b.toLowerCase());
    if (aIsHighlighted === bIsHighlighted) {
      return 0;
    } else {
      return aIsHighlighted ? -1 : 1;
    }
  });

  return (
    <Box
      sx={{
        py: 1,
        display: 'flex',
        flexFlow: 'row',
        overflowX: 'hidden',
        columnGap: 1,
      }}
      ref={carouselRef}
    >
      {sortedValues.map((value, i) => (
        <Chip
          variant="outlined"
          key={i}
          size="medium"
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            ...sxChip(value),
          }}
          label={formatLabel(value)}
        />
      ))}
    </Box>
  );
};

function formatLabel(str) {
  return capitalizeWords(removeLeadingHash(str));
}

function removeLeadingHash(str) {
  return str.charAt(0) === '#' ? str.slice(1) : str;
}

function capitalizeWords(str) {
  let words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}
