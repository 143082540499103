import { IconButton, Typography, Box, Stack } from '@mui/material';
//import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkIcon from '@mui/icons-material/Link';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import log from 'loglevel';

export default function SocialStack({ account }) {
  // We lack easy icons for tiktok, snapchat, and twitch.
  let social = [
    { icon: FacebookIcon, display: 'Facebook', url: account.facebook_url },
    { icon: InstagramIcon, display: 'Instagram', url: account.instagram_url },
    { icon: YouTubeIcon, display: 'YouTube', url: account.youtube_url },
    { icon: TwitterIcon, display: 'Twitter', url: account.twitter_url },
    { icon: PinterestIcon, display: 'Pinterest', url: account.pinterest_url },
    { icon: LinkedInIcon, display: 'LinkedIn', url: account.linkedIn_url },
    { icon: LinkIcon, display: 'Link', url: account.homepage },
  ];
  social = social.filter((x) => x.url);

  const homepage = social.find((x) => x.display === 'Link');
  if (homepage) social = social.filter((x) => x.display !== 'Link');

  return (
    <Stack direction="column" alignItems="flexStart" spacing={1}>
      <Stack direction="row" spacing={1}>
        {social.length > 0 &&
          social.map((sm) => (
            <SocialIconButton
              Icon={sm.icon}
              aria={sm.display}
              url={sm.url}
              key={sm.display}
            />
          ))}
      </Stack>
      {homepage && (
        <Box sx={{ display: 'inline-block' }}>
          <Stack
            direction="row"
            component="a"
            href={homepage.url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: 'inline-flex', // Ensures it wraps content tightly
              alignItems: 'center',
              textDecoration: 'none',
              color: 'primary.main',
            }}
          >
            <LinkIcon sx={{ fontSize: 28, mr: 1 }} />
            <Typography sx={{ fontWeight: 600, fontSize: 17 }}>
              {getDomain(homepage.url)}
            </Typography>
          </Stack>
        </Box>
      )}
    </Stack>
  );
}

const SocialIconButton = ({ Icon, aria = '', url }) => {
  return (
    <IconButton
      component={'a'}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={aria}
    >
      <Icon sx={{ fontSize: 28, color: '#3A3A40' }} />
    </IconButton>
  );
};

const getDomain = (url) => {
  try {
    const domain = new URL(url).hostname.replace('www.', '');
    return domain;
  } catch {
    log.warn('Invalid url provided to getDomain', url);
    return '';
  }
};
