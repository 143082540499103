import { Box, Modal } from '@mui/material';

interface ModalProps {
  children: React.ReactElement | React.ReactElement[];
  open: boolean;
  onClose: () => void;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}

const CustomModal = ({ children, open, onClose, size }: ModalProps) => {
  const getWidth = () => {
    switch (size) {
      case 'small':
        return 300;
      case 'medium':
        return 480;
      case 'large':
        return 600;
      case 'xlarge':
        return 850;
      default:
        return '95vw';
    }
  };
  const styles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: getWidth(),
    maxHeight: '90vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '8px',
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles}>{children}</Box>
    </Modal>
  );
};

export default CustomModal;
